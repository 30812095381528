import { View } from '@aws-amplify/ui-react';
import { FunctionComponent } from 'react';

const ResetHeader: FunctionComponent = () => (
  <View>
    Enter your email address. We will send you an email to reset your password.
  </View>
);

export default ResetHeader;
