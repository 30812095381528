import { useTheme, View } from '@aws-amplify/ui-react';
import { FunctionComponent, ReactNode } from 'react';

const Footer: FunctionComponent<{
  authName: string;
  custom?: ReactNode;
}> = ({ authName, custom }) => {
  const { tokens } = useTheme();
  const authNameLower = authName.toLowerCase();
  const version = process.env.GATSBY_VERSION || `0.0.0-${authNameLower}`;

  return (
    <View
      fontSize={tokens.fontSizes.small}
      padding={tokens.space.large}
      textAlign="center"
    >
      {custom && <View>{custom}</View>}
      <View
        className={`footer__${authNameLower ?? 'local'}`}
        padding={tokens.space.xxxs}
      >
        Version: {version}
      </View>
    </View>
  );
};

export default Footer;
