import { FunctionComponent, useState } from 'react';

import Hidden from './hidden';
import Input from './input';

const Name: FunctionComponent = () => {
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();

  // Build the avatar name if both first and last name are set
  let name = '';

  if (firstName && lastName) {
    name = `${firstName} ${lastName}`;
  }

  return (
    <>
      <Hidden
        name="picture"
        value={`https://ui-avatars.com/api/?background=ff5252&color=ffffff&name=${encodeURIComponent(
          name,
        )}`}
      />
      <Input
        name="given_name"
        placeholder="First Name"
        required={true}
        onChange={setFirstName}
      />
      <Input
        name="family_name"
        placeholder="Last Name"
        required={true}
        onChange={setLastName}
      />
    </>
  );
};

export default Name;
