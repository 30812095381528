import { TextField } from '@aws-amplify/ui-react';
import { FunctionComponent } from 'react';

const Hidden: FunctionComponent<{
  name: string;
  value: string;
}> = ({ name, value }) => (
  <TextField
    className="hidden__wrapper"
    type="hidden"
    label=""
    name={name}
    defaultValue={value}
  />
);

export default Hidden;
