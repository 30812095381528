import { Flex, Image, useTheme, View } from '@aws-amplify/ui-react';
import { FunctionComponent } from 'react';

const Logo: FunctionComponent<{
  color: string;
  label: string;
}> = ({ color, label }) => {
  const { tokens } = useTheme();
  return (
    <View padding={tokens.space.large} textAlign="center">
      <Flex alignItems="center" justifyContent="center">
        <Image
          src="https://www.honeassets.com/files/hone-logo-symbol.svg"
          alt="Hone"
          height={50}
          width={50}
        />
        <View
          color={color}
          fontSize={tokens.fontSizes.xxxl}
          fontWeight={tokens.fontWeights.bold}
          letterSpacing={tokens.space.xs}
          textTransform="uppercase"
        >
          {label}
        </View>
      </Flex>
    </View>
  );
};

export default Logo;
