import { View } from '@aws-amplify/ui-react';
import { FunctionComponent } from 'react';

const ResetConfirmHeader: FunctionComponent = () => (
  <View>
    Enter the code that was emailed to you and then enter and confirm your new
    password.
  </View>
);

export default ResetConfirmHeader;
