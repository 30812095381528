import { TextField, useTheme } from '@aws-amplify/ui-react';
import { ChangeEvent, ForwardedRef, FunctionComponent } from 'react';

const Input: FunctionComponent<{
  name: string;
  placeholder: string;
  required: boolean;
  inputRef?: ForwardedRef<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: (value: string) => void;
}> = ({ name, placeholder, required, inputRef, onChange }) => {
  const { tokens } = useTheme();
  return (
    <TextField
      ref={inputRef}
      label=""
      name={name}
      placeholder={placeholder}
      required={required}
      marginTop={tokens.space.xxxs}
      marginBottom={tokens.space.xxxs}
      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          onChange(ev.currentTarget.value);
        }
      }}
    />
  );
};

export default Input;
