import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react';
import { Auth as AmplifyAuth } from 'aws-amplify';
import { encode } from 'js-base64';
import { FunctionComponent, ReactNode } from 'react';

import buildService from '@/services/build-service';
import configService from '@/services/config-service';

import Footer from './footer';
import Logo from './logo';
import ResetConfirmHeader from './reset-confirm-header';
import ResetHeader from './reset-header';

const config = configService.getConfig();

// If there is an error show the error page
if (config.error && buildService.isBrowser()) {
  window.location.replace(`/error/?e=${config.error}`);
}

const Auth: FunctionComponent<{
  logo: {
    color: string;
    label: string;
  };
  forcePassword?: ReactNode;
  footer?: ReactNode;
  onSignIn?: (response: any) => void;
  onCreateClaims?: (idToken: any, defaultClaims: any) => any;
}> = ({ logo, forcePassword, footer, onSignIn, onCreateClaims }) => {
  // Render the component only if no errors
  if (config.error) {
    return null;
  }

  // Otherwise render auth component
  const stage = config.banner?.stage?.toLowerCase();

  return (
    <ThemeProvider>
      <Authenticator
        loginMechanisms={['email']}
        hideSignUp={true}
        components={{
          Header: () => <Logo {...logo} />,
          Footer: () => <Footer authName={config.authName} custom={footer} />,
          ForceNewPassword: {
            FormFields: () => <>{forcePassword}</>,
          },
          ResetPassword: {
            Header: () => <ResetHeader />,
          },
          ConfirmResetPassword: {
            Header: () => <ResetConfirmHeader />,
          },
          SignIn: {
            Header: () => (
              <>
                {config.banner && (
                  <div
                    className={`banner__wrapper banner__${stage ?? 'local'}`}
                    dangerouslySetInnerHTML={{
                      __html: config.banner.lines
                        .map((line) => `<p>${line}</p>`)
                        .join(''),
                    }}
                  ></div>
                )}
              </>
            ),
          },
        }}
        services={{
          handleSignIn: ({ username, password }) =>
            AmplifyAuth.signIn(username, password).then((response) => {
              // Allow the host component to access the sign in response
              if (onSignIn) {
                onSignIn(response);
              }

              return response;
            }),
        }}
      >
        {({ user }) => {
          if (user && buildService.isBrowser()) {
            const session = user.getSignInUserSession();
            const idToken = session?.getIdToken();
            const accessToken = session?.getAccessToken();
            const refreshToken = session?.getRefreshToken();
            const expiresIn = idToken
              ? idToken.getExpiration() - idToken.getIssuedAt()
              : 0;

            // Build the claims to include the return data
            let claims = {
              firstName: idToken?.payload.given_name,
              lastName: idToken?.payload.family_name,
              avatarUrl: idToken?.payload.picture,
            };

            if (onCreateClaims) {
              claims = onCreateClaims(idToken?.payload, claims);
            }

            // Stringify the user tokens and expiry
            const data = JSON.stringify({
              idToken: idToken?.getJwtToken(),
              accessToken: accessToken?.getJwtToken(),
              refreshToken: refreshToken?.getToken(),
              expiresIn,
              claims,
            });

            // Encode the tokens string and redirect to the provided uri
            const encoded = encode(data, true);
            window.location.replace(`${config.redirectUri}?d=${encoded}`);
          }

          return <></>;
        }}
      </Authenticator>
    </ThemeProvider>
  );
};

export default Auth;
