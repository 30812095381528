import { Translations } from '@aws-amplify/ui-components';
import { Authenticator, useTheme, View } from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify';
import { FunctionComponent } from 'react';

import Auth from '@/components/auth';
import Name from '@/components/name';
import TimeZone from '@/components/time-zone';

I18n.setLanguage('en-AU');
I18n.putVocabulariesForLanguage('en-AU', {
  [Translations.CHANGE_PASSWORD]: 'Submit',
});

const ForcePassword: FunctionComponent = () => {
  return (
    <>
      <View>
        <TimeZone />
        <Name />
      </View>
      <Authenticator.ForceNewPassword.FormFields />
    </>
  );
};

const Footer: FunctionComponent = () => {
  const { tokens } = useTheme();
  return (
    <View className="footer__message" marginBottom={tokens.space.medium}>
      UNAUTHORISED ACCESS PROHIBITED
    </View>
  );
};

const InternalPage: FunctionComponent = () => {
  const groupsKey = 'cognito:groups';
  const { tokens } = useTheme();

  return (
    <Auth
      logo={{
        color: tokens.colors.overlay[70].value,
        label: 'Internal',
      }}
      forcePassword={<ForcePassword />}
      footer={<Footer />}
      onCreateClaims={(idToken, claims) => ({
        ...claims,
        userId: idToken.email,
        groups: idToken[groupsKey],
      })}
    />
  );
};

export default InternalPage;
